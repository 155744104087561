@import "../../../styles/variables";

.workbooksCatalogComponent {

  .workbooksCatalog {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: stretch;

    .workbookCard {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 7px;

      border-bottom: 1px solid @border-color-1;
      border-left: 1px solid @border-color-1;
      border-right: 1px solid @border-color-1;

      &:first-child {
        border-top: 1px solid @border-color-1;
        border-top-left-radius: @border-radius * 2;
        border-top-right-radius: @border-radius * 2;
      }

      &:last-child {
        border-bottom-left-radius: @border-radius * 2;
        border-bottom-right-radius: @border-radius * 2;
      }

      .workbookCardHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .icon {
          margin-right: 10px;
          font-size: 20px;
          color: @foreground-primary;
          opacity: 0.7;
        }

        .workbookName {
          font-weight: 600;
          color: @font-base-color;
          line-height: 20px;

          flex-grow: 1; /* Ensures the name takes up available space between icon and stores */
        }

        .workbookStores {
          display: flex;
          justify-content: flex-end;
          gap: 10px; /* Optional: space between store names if there are multiple */

          color: #797986;
          font-size: 11px;
          line-height: 1.5em;
        }
      }

      &:hover {
        box-shadow: 0 4px 30px rgba(48,48,54,.04);
        span {
          opacity: 1.0;
        }
      }
    }
  }
}
