import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Callout, Intent } from '@blueprintjs/core';
import * as styles from './templateCatalog.module.less';
import API from '../../../config';
import Logger from '../../../models/logger';
import xlsxLogo from '../../../assets/images/xlsx-icon.svg';
import bulkEditorLogo from '../../../assets/images/logo/brandmark-green-200.png';
import analyticsLogo from '../../../assets/images/logo/brandmark-blue-200.png';
import AxiosClient from '../../../lib/AxiosClient';
import AuthService from '../../../lib/AuthService';

const customSelectStyles = {
  control: provided => ({
    ...provided,
    minHeight: '39px',
    background: '#427762',
    fontWeight: '600',
    color: 'white',
    border: 'none', // Remove the border
    boxShadow: 'none', // Remove the box shadow (if any)
    '&:hover': {
      border: 'none', // Prevent border on hover
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '39px',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '5px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'white',
    padding: '5px',
  }),
};

/**
 * A dialog that allows the user to add a new Workbook
 */
class TemplateCatalog extends Component {
  constructor(props) {
    super(props);

    /* Create our logger */
    this.logger = new Logger('TemplateCatalog');

    /* Parent functions we passed to the component */
    this.parentFunctions = {
      openWorkbookModal: props.openWorkbookModal,
    };

    this.state = {
      workspaceShopifyStores: props.workspaceShopifyStores,
      workspaceId: props.workspaceId,
      // workspaceShopifyStores: {},
      selectedShopifyStore: null,
      connectedShopifyStores: [],
      connectedShopifyStoresSelectOptions: [],
      // shopifyStoresStillDownloadingData: [], // List of store urls that are still doing their initial data download into Mixtable
      working: false,
      selectedTemplate: null,
      shopifyWorkbookTemplatesForStore: [],
    };

    this.shopifyWorkbookTemplates = {
      basicSection: {
        title: 'Basics',
        templates: [
          {
            id: 'start-from-scratch',
            title: 'Blank Excel-like Workbook',
            description: 'The same, blank workbook that you get in Excel, and add the data you need',
          },
        ],
      },
      bulkEditorSection: {
        title: 'Spreadsheet Editor App / Excel and CSV Export App',
        icon: bulkEditorLogo,
        templates: [
          // {
          //   id: 'products-and-collections',
          //   title: 'Products and Collections',
          //   description: 'Worksheets for all your products and collections, with the columns folks most often need',
          // },
          {
            id: 'basic-product-info',
            title: 'Basic Product Info',
            description: 'Worksheet for all your products (without metafields)',
          },
          {
            id: 'full-product-info',
            title: 'Full Product Info',
            description: 'Worksheet for all your product variants and their inventories',
          },
          {
            id: 'product-metafields',
            title: 'Product Metafields',
            description: 'Worksheets for all your product and variants metafields',
          },
          {
            id: 'product-inventory',
            title: 'Inventory',
            description: 'Worksheet for all your products and their inventory levels',
          },
          {
            id: 'collections',
            title: 'Collections',
            description: 'Manage your store\'s collections, and the products in them',
          },
          {
            id: 'international-prices',
            title: 'International Prices',
            description: 'Worksheet for all your products and international market prices',
          },
          {
            id: 'google-shopping-fields',
            title: 'Google Shopping',
            description: 'Worksheet for all your products and their Google Shopping fields',
          },
          {
            id: 'orders',
            title: 'Orders',
            description: 'Worksheets for all your orders and order line items',
          },
          {
            id: 'customers',
            title: 'Customers',
            description: 'Worksheet for all your customers',
          },
          {
            id: 'pages',
            title: 'Pages',
            description: 'Worksheet for all your pages',
          },
          {
            id: 'blogs-and-articles',
            title: 'Blogs and Blog Posts',
            description: 'Worksheets for all your blogs and blog post',
          },
        ],
      },
      analyticsSection: {
        title: 'For Mixtable Analytics App',
        icon: analyticsLogo,
        templates: [
          {
            id: 'product-annual-sales',
            title: 'Annual Sales',
            description: 'Product net and gross order sales, for all time and for each calendar year',
          },
          {
            id: 'product-monthly-sales',
            title: 'Monthly Sales',
            description: 'Product net and gross order monthly sales',
          },
          {
            id: 'product-orders',
            title: 'Product Orders',
            description: 'Product order numbers for all time, for each calendar year and last 24 months',
          },
          {
            id: 'holiday-sales',
            title: 'Holiday Sales',
            description: 'Product net sales, for the last few holiday seasons',
          },
        ],
      },
    };

    // Store the Auth provider
    this.authProvider = AuthService.getInstance().provider;

    // Get the shopify app bridge and app bridge API key
    if (this.authProvider === 'shopify') {
      this.appBridgeApp = AuthService.getInstance().shopify;
    }
  }

  /**
   * Creates the actual workbook
   * @param template
   * @param storeId
   * @returns {Promise<void>}
   */
  createWorkbook = async (template, storeId) => {
    this.setState({ showBlockingOverlay: true }, async () => {
      const selectedTemplate = [...this.shopifyWorkbookTemplates.bulkEditorSection.templates, ...this.shopifyWorkbookTemplates.analyticsSection.templates].find(t => t.id === template);
      try {
        const response = await AxiosClient.getInstance().post(`${API.API_URL}/1.0/workbooks/`, {
          workspaceId: this.state.workspaceId,
          name: selectedTemplate ? selectedTemplate.title : null,
        });

        const masterEdition = response.data.masterEdition;

        let urlToRedirect = `/workbook/${masterEdition.id}/${masterEdition.worksheets[0].id}?new=true&`;

        if (template && template !== 'start-from-scratch' && storeId) {
          urlToRedirect += `template=${template}&storeId=${storeId}`;
        }

        /* If we are in the shopify admin, open workbook in modal. Else, redirect. */
        if (this.appBridgeApp) {
          this.parentFunctions.openWorkbookModal(urlToRedirect, response.data.workbook.name);
        } else {
          this.props.history.push(urlToRedirect);
        }
      } catch (err) {
        console.log(err);
        this.parentFunctions.showError(err);
      }
    });
  }

  /** Sets the stores select options */
  setStoresSelectOptions = () => {
    const connectedShopifyStores = this.state.workspaceShopifyStores ? Object.keys(this.state.workspaceShopifyStores) : [];
    const connectedShopifyStoresSelectOptions = connectedShopifyStores.map(store => ({ value: store, label: store }));

    if (connectedShopifyStores && connectedShopifyStores.length > 0) {
      this.selectStore(connectedShopifyStores[0]);
    }

    this.setState({
      connectedShopifyStores,
      connectedShopifyStoresSelectOptions,
    });
  }

  async componentDidMount() {
    await this.setStoresSelectOptions();
  }

  /**
   * Selects/Deselects template and saves it to the state
   * @param templateId
   * @param value
   */
  selectTemplate = (templateId, value) => {
    this.setState({
      selectedTemplate: value ? templateId : null,
    });
  }

  /**
   * Selects store, sets selectedStoreCanUseAnalytics and nullifies the selected template
   * @param selectedShopifyStoreId
   */
  selectStore = (selectedShopifyStoreId) => {
    this.setState({
      selectedShopifyStore: selectedShopifyStoreId,

      storeHasAnalyticsAppInstalled: this.state.workspaceShopifyStores[selectedShopifyStoreId] && this.state.workspaceShopifyStores[selectedShopifyStoreId].apps.analytics,
      storeHasBulkEditorAppInstalled: this.state.workspaceShopifyStores[selectedShopifyStoreId] && this.state.workspaceShopifyStores[selectedShopifyStoreId].apps.bulk_editor,
      storeHasExporterAppInstalled: this.state.workspaceShopifyStores[selectedShopifyStoreId] && this.state.workspaceShopifyStores[selectedShopifyStoreId].apps.exporter,

      shopifyWorkbookTemplatesForStore: [],
    }, () => {
      let shopifyWorkbookTemplatesForStore = [].concat(
        _.map(this.shopifyWorkbookTemplates.basicSection.templates, template => ({
          ...template,
          section: 'basic',
        })),
      );

      if (this.state.storeHasBulkEditorAppInstalled || this.state.storeHasExporterAppInstalled) {
        shopifyWorkbookTemplatesForStore = shopifyWorkbookTemplatesForStore.concat(
          _.map(this.shopifyWorkbookTemplates.bulkEditorSection.templates, template => ({
            ...template,
            section: 'bulkEditor',
          })),
        );
      }

      if (this.state.storeHasAnalyticsAppInstalled) {
        shopifyWorkbookTemplatesForStore = shopifyWorkbookTemplatesForStore.concat(
          _.map(this.shopifyWorkbookTemplates.analyticsSection.templates, template => ({
            ...template,
            section: 'analytics',
          })),
        );
      }

      this.setState({
        shopifyWorkbookTemplatesForStore: shopifyWorkbookTemplatesForStore,
      });
    });
  }

  render() {
    return (
      <div className={styles.templateCatalogComponent}>

        <div>
          <h2>Create workbook</h2>
        </div>

        <div className={styles.templateIntro}>
          Start with one of our templates. These pre-made worksheets include the data users need most often. Each worksheet comes with commonly used columns, giving you an excellent starting point. You can easily add or remove data to suit your needs, but these workbooks provide a solid foundation for most users.
        </div>

        {/* Store selector*/}
        <div>
          {/* You still need to add a store*/}
          {this.state.connectedShopifyStores.length === 0 &&
            <Callout intent={Intent.WARNING} style={{ paddingTop: '5px', paddingBottom: '5px', paddingLeft: '45px' }}>
              <div>You need to connect a Shopify store to Mixtable</div>
            </Callout>
          }

          {/* Dropdown showing list of stores*/}
          {this.state.connectedShopifyStores.length > 0 && <div>
            {/* Shopify store selector - show if we have more than one store */}
            {this.state.connectedShopifyStores.length > 1 && <div style={{ margin: '10px 0 10px 0', height: '60px' }}>
              <Select
                styles={customSelectStyles}
                placeholder={'Select a Shopify store'}
                value={_.find(this.state.connectedShopifyStoresSelectOptions, { value: this.state.selectedShopifyStore })}
                onChange={((filterValue) => {
                  this.selectStore(filterValue.value);
                })}
                options={this.state.connectedShopifyStoresSelectOptions}
              />
            </div>}
          </div>}

          {this.state.shopifyWorkbookTemplatesForStore.length === 0 && <div style={{ margin: '10px 0 10px 0' }}>
            <Callout intent={Intent.WARNING}>
              <div>This store does not have any of our apps installed</div>
            </Callout>
          </div>}
        </div>


        {/* Show the templates applicable to the store*/}
        <div className={styles.templatesList}>

          {this.state.shopifyWorkbookTemplatesForStore.map(template => (
            <div key={template.id}
              className={styles.template}
              onClick={async () => {
                await this.createWorkbook(template.id, this.state.selectedShopifyStore);
              }}>
              <div>
                <div className={styles.content}>
                  <div className={styles.title}>
                    {template.section === 'basic' && <img className={styles.sectionIcon} src={xlsxLogo} alt={'Excel logo'}/>}
                    {template.section === 'bulkEditor' && <img className={styles.sectionIcon} src={bulkEditorLogo} alt={'Excel logo'}/>}
                    {template.section === 'analytics' && <img className={styles.sectionIcon} src={analyticsLogo} alt={'Excel logo'}/>}
                    {template.title}
                  </div>
                  <div className={styles.description}>
                    {template.description}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(TemplateCatalog);
